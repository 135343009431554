.card {
  @apply rounded-tl py-4 px-2;
  border-style: solid;
}

.card.transform:hover {
  transform: translate3d(0.05rem, 0.05rem, 0);
}

.card.transform:active {
  transform: translate3d(0.2rem, 0.2rem, 0);
}

.card.disabled {
  opacity: 0.2;
  transform: none !important;
}

.card::after {
  @apply z-10  absolute top-[0.05rem] -right-[0.5rem] h-[calc(100%+4px)] w-[0.5rem] rounded-tr rounded-tl rounded-br bg-transparent inline-block skew-y-[45deg] -rotate-[0deg]   border-t-[2px]  border-r-[2px] border-b-[2px] border-l-[2px] border-black;
  content: '';
  background-color: var(--shadow-color, transparent);
  border-style: solid;
}

.card::before {
  @apply z-10  absolute -bottom-[0.5rem] right-0 left-0 w-[calc(100%+4px)] h-[0.5rem] rounded-bl rounded-tl rounded-br  bg-transparent inline-block skew-x-[45deg] -rotate-[0deg] border-b-[2px] border-l-[2px] border-t-[2px] border-black;
  content: '';
  background-color: var(--shadow-color, transparent);
  border-style: solid;
}
