.Symbol_btn__mnpmn {
  border-radius: 0.25rem;
  border-top-width: 2px;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  padding: 0.25rem;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
  border-style: solid
}
 

.Symbol_shadow_right__tbsqw {
  position: absolute;
  top: 0.03rem;
  right: -0.3rem;
  z-index: 10;
  display: inline-block;
  height: calc(100% + 2px);
  width: 0.5rem;
  --tw-rotate: -0deg;
  --tw-skew-y: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: transparent;
  content: '';
  background-color: #c2baaf;
  border-style: solid
}

.Symbol_shadow_bottom__CTzZz {
  position: absolute;
  bottom: -0.3rem;
  right: 0px;
  left: 0px;
  z-index: auto;
  display: inline-block;
  height: 0.5rem;
  width: calc(100% + 2px);
  --tw-rotate: -0deg;
  --tw-skew-x: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: transparent;
  content: '';
  background-color: #c2baaf;
  border-style: solid
}

.btn {
  border-style: solid;
}
.btn.transform:hover {
  transform: translate3d(0.05rem, 0.05rem, 0);
}

.btn.transform:active {
  transform: translate3d(0.2rem, 0.2rem, 0);
}

 
/* shadow-right */
.btn::after {
  position: absolute;
  top: 0.03rem;
  right: -0.3rem;
  z-index: 10;
  display: inline-block;
  height: calc(100% + 2px);
  width: 0.5rem;
  --tw-rotate: -0deg;
  --tw-skew-y: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: transparent;
  content: '';
  background-color: var(--shadow-color, transparent) !important;
  border-style: solid;
}
.btn::before {
  position: absolute;
  bottom: -0.3rem;
  left: -0rem;
  z-index: 10;
  display: inline-block;
  height: 0.5rem;
  width: calc(100% + 2px);
  --tw-rotate: -0deg;
  --tw-skew-x: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: transparent;
  content: '';
  background-color: var(--shadow-color, transparent) !important;
  border-style: solid;
}


.shadow-yellow{
  --shadow-color: #A37F35;
}
.shadow-dark{
  --shadow-color: #3C382F;
}

.shadow-green{
  --shadow-color: #8C9535;
}

.shadow-gray{
  --shadow-color: #8D8980;
}
.bag_tabsTrigger__oucB_ {
    width: 100%;
    border-top-right-radius: calc(var(--radius) - 4px);
    border-top-left-radius: calc(var(--radius) - 4px);
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
    border-width: 2px;
    border-bottom-width: 0px;
    --tw-border-opacity: 1;
    border-color: rgb(71 94 109 / var(--tw-border-opacity));
    background-color: transparent;
    --tw-bg-opacity: 1;
    background-color: rgb(114 137 162 / var(--tw-bg-opacity));
    font-size: 1rem;
    line-height: 1.5rem;
    --tw-text-opacity: 1;
    color: rgb(255 255 255 / var(--tw-text-opacity))
}
.bag_tabsTrigger__oucB_[data-state='active'] {
    --tw-border-opacity: 1 !important;
    border-color: rgb(0 0 0 / var(--tw-border-opacity)) !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(247 195 52 / var(--tw-bg-opacity)) !important;
    --tw-text-opacity: 1 !important;
    color: rgb(0 0 0 / var(--tw-text-opacity)) !important
}

.bag_toastContainer__CeFI0 {
    border-width: 0px !important;
    --tw-bg-opacity: 1 !important;
    background-color: rgb(79 74 62 / var(--tw-bg-opacity)) !important;
    font-family: comicbd;
    --tw-text-opacity: 1 !important;
    color: rgb(247 195 52 / var(--tw-text-opacity)) !important
}

@font-face {
font-family: '__geistSans_ef23df';
src: url(/_next/static/media/4473ecc91f70f139-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__geistSans_Fallback_ef23df';src: local("Arial");ascent-override: 85.83%;descent-override: 20.52%;line-gap-override: 9.33%;size-adjust: 107.19%
}.__className_ef23df {font-family: '__geistSans_ef23df', '__geistSans_Fallback_ef23df'
}.__variable_ef23df {--font-geist-sans: '__geistSans_ef23df', '__geistSans_Fallback_ef23df'
}

@font-face {
font-family: '__geistMono_1235f0';
src: url(/_next/static/media/463dafcda517f24f-s.p.woff) format('woff');
font-display: swap;
}@font-face {font-family: '__geistMono_Fallback_1235f0';src: local("Arial");ascent-override: 69.97%;descent-override: 16.73%;line-gap-override: 7.61%;size-adjust: 131.49%
}.__className_1235f0 {font-family: '__geistMono_1235f0', '__geistMono_Fallback_1235f0'
}.__variable_1235f0 {--font-geist-mono: '__geistMono_1235f0', '__geistMono_Fallback_1235f0'
}

.card {
  border-top-left-radius: 0.25rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  border-style: solid;
}

.card.transform:hover {
  transform: translate3d(0.05rem, 0.05rem, 0);
}

.card.transform:active {
  transform: translate3d(0.2rem, 0.2rem, 0);
}

.card.disabled {
  opacity: 0.2;
  transform: none !important;
}

.card::after {
  position: absolute;
  top: 0.05rem;
  right: -0.5rem;
  z-index: 10;
  display: inline-block;
  height: calc(100% + 4px);
  width: 0.5rem;
  --tw-rotate: -0deg;
  --tw-skew-y: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-top-right-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-top-width: 2px;
  border-right-width: 2px;
  border-bottom-width: 2px;
  border-left-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: transparent;
  content: '';
  background-color: var(--shadow-color, transparent);
  border-style: solid;
}

.card::before {
  position: absolute;
  bottom: -0.5rem;
  right: 0px;
  left: 0px;
  z-index: 10;
  display: inline-block;
  height: 0.5rem;
  width: calc(100% + 4px);
  --tw-rotate: -0deg;
  --tw-skew-x: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  border-bottom-left-radius: 0.25rem;
  border-top-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  border-bottom-width: 2px;
  border-left-width: 2px;
  border-top-width: 2px;
  --tw-border-opacity: 1;
  border-color: rgb(0 0 0 / var(--tw-border-opacity));
  background-color: transparent;
  content: '';
  background-color: var(--shadow-color, transparent);
  border-style: solid;
}

.stroke-darkred {
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: black; */
  text-shadow: 2px 0 #9e2703, -2px 0 #9e2703, 0 2px #9e2703, 0 -2px #9e2703,
    1px 1px #9e2703, -1px -1px #9e2703, 1px -1px #9e2703, -1px 1px #9e2703;
}

.stroke-gray {
  text-shadow: 2px 0 #878B83, -2px 0 #878B83, 0 2px #878B83, 0 -2px #878B83,
    1px 1px #878B83, -1px -1px #878B83, 1px -1px #878B83, -1px 1px #878B83;
}


.CoinPickupEffect_coinBounce__geCmg {
    bottom: 4.5rem;
    opacity: 1
}  

