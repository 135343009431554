.stroke-darkred {
  /* -webkit-text-stroke-width: 1px; */
  /* -webkit-text-stroke-color: black; */
  text-shadow: 2px 0 #9e2703, -2px 0 #9e2703, 0 2px #9e2703, 0 -2px #9e2703,
    1px 1px #9e2703, -1px -1px #9e2703, 1px -1px #9e2703, -1px 1px #9e2703;
}

.stroke-gray {
  text-shadow: 2px 0 #878B83, -2px 0 #878B83, 0 2px #878B83, 0 -2px #878B83,
    1px 1px #878B83, -1px -1px #878B83, 1px -1px #878B83, -1px 1px #878B83;
}
