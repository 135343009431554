.btn {
  @apply border-t-[2px] border-l-[2px] border-black   p-1   rounded transition-all;
  border-style: solid;
}
 

.shadow_right {
  @apply z-10  absolute top-[0.03rem] -right-[0.3rem] h-[calc(100%+2px)] w-[0.5rem] rounded-tr rounded-tl rounded-br bg-transparent inline-block skew-y-[45deg] -rotate-[0deg]   border-t-[2px]  border-r-[2px] border-b-[2px] border-l-[2px] border-black;
  content: '';
  background-color: #c2baaf;
  border-style: solid;
}

.shadow_bottom {
  @apply z-auto  absolute -bottom-[0.3rem] right-0 left-0 w-[calc(100%+2px)] h-[0.5rem] rounded-bl rounded-tl rounded-br  bg-transparent inline-block skew-x-[45deg] -rotate-[0deg] border-b-[2px] border-l-[2px] border-t-[2px] border-black;
  content: '';
  background-color: #c2baaf;
  border-style: solid;
}
