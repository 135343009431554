.btn {
  border-style: solid;
}
.btn.transform:hover {
  transform: translate3d(0.05rem, 0.05rem, 0);
}

.btn.transform:active {
  transform: translate3d(0.2rem, 0.2rem, 0);
}

 
/* shadow-right */
.btn::after {
  @apply z-10  absolute top-[0.03rem] -right-[0.3rem] h-[calc(100%+2px)] w-[0.5rem] rounded-tr rounded-tl rounded-br bg-transparent inline-block skew-y-[45deg] -rotate-[0deg]   border-t-[2px]  border-r-[2px] border-b-[2px] border-l-[2px] border-black;
  content: '';
  background-color: var(--shadow-color, transparent) !important;
  border-style: solid;
}
.btn::before {
  @apply z-10  absolute -bottom-[0.3rem]  -left-[0rem] w-[calc(100%+2px)] h-[0.5rem] rounded-bl rounded-tl  rounded-br  bg-transparent inline-block skew-x-[45deg] -rotate-[0deg] border-b-[2px] border-l-[2px] border-t-[2px] border-black;
  content: '';
  background-color: var(--shadow-color, transparent) !important;
  border-style: solid;
}


.shadow-yellow{
  --shadow-color: #A37F35;
}
.shadow-dark{
  --shadow-color: #3C382F;
}

.shadow-green{
  --shadow-color: #8C9535;
}

.shadow-gray{
  --shadow-color: #8D8980;
}