.tabsTrigger {
  @apply bg-transparent border-[2px] border-b-0 border-[#475E6D] rounded-tr-sm rounded-tl-sm bg-[#7289A2] rounded-br-none rounded-bl-none text-white  w-full text-base;
}
.tabsTrigger[data-state='active'] {
  @apply !bg-[#F7C334] !text-black !border-black;
}

.toastContainer {
  @apply !bg-[#4F4A3E] !text-[#F7C334] !border-0 font-comicbd;
}
